<template>
  <div>
    <v-layout wrap pt-7>
      <v-flex xs12 pb-lg-16>
        <v-img width="100%" height="cover" src="../assets/Images/BG.jpg">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-img
                class="mx-auto"
                max-width="500px"
                src="../assets/Images/ourImpact.png"
              ></v-img>
            </v-flex>
            <v-flex xs10 pt-6>
              <v-layout wrap pt-lg-16>
                <v-flex sm6 md6  lg3 v-for="(item, i) in blocks" :key="i">
                  <v-layout wrap justify-center px-4>
                    <v-flex xs12 pt-8>
                      <v-img
                        :src="require('../assets/Images/' + item.image)"
                      ></v-img>
                    </v-flex>
                    <v-flex xs12 pt-5 pb-10 class="popregular medium">
                      <span>
                        <b> {{ item.count }}</b>
                      </span>
                      <span>{{ item.text }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs10 pr-lg-6 text-right pb-sm-10  pb-md-0>
              <span class="popregular medium">
                <i> *All numbers as on 31 March 2020</i></span
              >
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
      <v-flex xs12 py-16 style="background-color: #005f32">
        <span class="popregular xlarge" style="color: white"
          >Email all your donation queries to us on donations@wti.org.in</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      blocks: [
        {
          image: "asset1.png",
          count: "48,154",
          text: "animal lives saved",
        },
        {
          image: "asset2.png",
          count: "2,56,81,431",
          text: "hectares of natural habitats secured",
        },
        {
          image: "asset3.png",
          count: "3",
          text: "threatened species recovered",
        },
        {
          image: "asset4.png",
          count: "2103",
          text: "elephants saved from train collisions",
        },
        {
          image: "asset5.png",
          count: "247",
          text: "enforcement operations to dismantle wildlife trade & trafficking",
        },
        {
          image: "asset6.png",
          count: "18,941",
          text: "forest guards trained as part of the Van Rakshak Project",
        },
        {
          image: "asset7.png",
          count: "19,444",
          text: "families enabled with green livelihoods, reducing dependence on wildlife",
        },
        {
          image: "asset8.png",
          count: "24,45,341",
          text: "children made aware of conservation",
        },
      ],
    };
  },
};
</script>